import React from "react";
import Typewriter from "typewriter-effect";
import "./homehead.css";
import arrow from "../assets/images/Arrow.png";

export default function homehead() {
  return (
    <div className="homeHeadContainer">
      <div className="homehead">
        <p className="welcome">Welcome to Neuralearn.ai</p>
        <div className=" master-deep">
          <Typewriter
            options={{
              strings: ["Master", "Understand", "Apprehend"],
              autoStart: true,
              loop: true,
            }}
          />
          <span className="master">Deep Learning</span>
        </div>
        <div className="gainContainer">
          Gain world class skills on how to develop deep learning algorithms for
          computer vision, natural language processing and signal processing.
          <br /> Go from absolute beginner to getting <span>Hired</span>
        </div>
        <div className="start">
          <div className="h5 mb-0 bounce-container">
            HOW IT STARTED{" "}
            <div className="downArrow " id="helper">
              {" "}
              <img
                width="40"
                height="40"
                alt=""
                src={arrow}
                className="arrow1"
              />{" "}
            </div>
          </div>
          <div className="skills">Basic Python Skills & No Math Background</div>
          <div className="h5 mb-0 bounce-container">
            HOW IT’S GOING{" "}
            <div className="downArrow " id="helper">
              {" "}
              <img
                width="40"
                height="40"
                alt=""
                src={arrow}
                className="arrow1"
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
