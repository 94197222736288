import React from 'react'
import './Carosel4.css'

import c1 from '../../../assets/images/c1.png'
import c2 from '../../../assets/images/c2.png'
import c3 from '../../../assets/images/c3.png'
import c4 from '../../../assets/images/c4.png'

const Carosel4 = () => {
  return (
    // <motion.div whileInView={{ x: [-100, 0], opacity: [0, 1] }} transition={{ duration: 1.5 }}>
    <div>
      <div className='builtContainer'>
        <div className='main-built'>
          <div className=' built1'>
            <div className=' buit-text mb-5'>
              Built And Deployed
              <br /> Modern NLP Solutions
            </div>
            <div>
              <img className='image1' src={c1} alt='' />
            </div>
          </div>
          <div className=''>
            <div className=''>
              <div>
                <img className='c2' src={c2} alt='' />
              </div>
              <div className='image3Container'>
                <img className='c3' src={c3} alt='' />
              </div>
            </div>
          </div>
          <div className='image4-container'>
            <img className='c4' src={c4} alt='' />
            <div className='bubbles'>
              <div className='buble1'></div>
              <div className='buble2'></div>
              <div className='buble3'></div>
              <div className='buble4'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  // </motion.div>
  )
}

export default Carosel4
