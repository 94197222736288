import React from "react";
import obj_img from "../../assets/images/new/obj_image.png";
import human from "../../assets/images/new/human.png";
import graph from "../../assets/images/new/graph.png";
import green from "../../assets/images/new/green.png";
import colab from "../../assets/images/new/colab.png";
import heroku from "../../assets/images/new/heroku.png";
const ObjectDetetion = () => {
  return (
    <div>
      <div>
        <div className="learningPathbody">
          <section className="learning-path-html">
            <h2 className="title my-5">Learning Path</h2>

            <section className="path">
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">Introduction</h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={obj_img} alt="intro" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Welcome</p>
                    </div>
                    <div className="lesson">
                      <p>General Introduction</p>
                    </div>
                    <div className="lesson">
                      <p>Course Structure</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        Theoritical background
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={human} alt="intro" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    {/* <div className="lesson">
                      <p>Welcome</p>
                    </div>
                    <div className="lesson">
                      <p>Learn stuffs</p>
                    </div> */}
                    <div className="lesson">
                      <p>Haar Cascades and Histogram of gradients</p>
                    </div>
                    {/* <div className="lesson">
                      <p>Welcome Lorem, ipsum.</p>
                    </div>
                    <div className="lesson">
                      <p>Learn stuffs</p>
                    </div> */}
                    <div className="lesson">
                      <p>Convolutional Neural Networks</p>
                    </div>
                    <div className="lesson">
                      <p>RCNN,FastRCNN, FaterRCNN</p>
                    </div>
                    {/* <div className="lesson">
                      <p>Learn stuffs</p>
                    </div>
                    <div className="lesson">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing.
                      </p>
                    </div> */}
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        Single Stage Algorithms
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={graph} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    {/* <div className="lesson">
                      <p>Welcome</p>
                    </div>
                    <div className="lesson">
                      <p>Learn stuffs</p>
                    </div> */}
                    <div className="lesson">
                      <p>Understanding YOLO (You Only Look Once)</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Understanding YOLOX</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        Single Stage Algorithms
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={green} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    {/* <div className="lesson">
                      <p>Welcome</p>
                    </div>
                    <div className="lesson">
                      <p>Learn stuffs</p>
                    </div> */}
                    <div className="lesson">
                      <p>Pascal VOC dataset</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Preparing a custom dataset with Remo</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        Finetuning and Testing
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={colab} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Training</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Wandb integration</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Running inference on Onnx model</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">Deployment</h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={heroku} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Understanding how APIs work</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Building an API with Fastapi</p>
                    </div>

                    <div className="lesson">
                      <p>Deploying on Heroku</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Load Testing with Locust</p>
                    </div>
                  </div>
                </div>
              </article>
            </section>
          </section>

          <div className="mt-5 ms-5" style={{ paddingTop: "1rem" }}>
            <h2 className="title mt-5">Learning Never Ends!!!</h2>
            <h2
              className="mt-3"
              style={{
                color: "#D16500",
                fontSize: "2rem",
              }}
            >
              This Course Will Constantly be Updated
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ObjectDetetion;
