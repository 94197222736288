import React from 'react'

import './Carosel3.css'
import cf1 from '../../../assets/images/cf1.png'
import cf2 from '../../../assets/images/cf2.png'
import cf3 from '../../../assets/images/cf3.png'
import cf4 from '../../../assets/images/cf4.png'
const Carosel3 = () => {
  return (
    // <motion.div whileInView={{ x: [-100, 0], opacity: [0, 1] }} transition={{ duration: 1.5 }}>
    <div className='builtContainer'>
      <div className='main-built'>
        <div className=' built1'>
          <div className='buit-text3'>
            Built and Deployed State of art
            <br /> Object detection Models
          </div>
          <div>
            <img className='cf1' src={cf1} alt='' />
          </div>
        </div>
        <div className=''>
          <div className=''>
            <div>
              <img className='cf2' src={cf2} alt='' />
            </div>
            <div className='image3Container'>
              <img className='cf3' src={cf3} alt='' />
            </div>
          </div>
        </div>
        <div className='image4-container'>
          <img className='cf4' src={cf4} alt='' />
          <div className='bubbles3'>
            <div className='buble1'></div>
            <div className='buble2'></div>
            <div className='buble3'></div>
            <div className='buble4'></div>
          </div>
        </div>
      </div>
    </div>
  // </motion.div>
  )
}

export default Carosel3
