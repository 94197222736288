import gcomputerVision from '../assets/gif/gif_computer_vision.gif'
import gnlp from '../assets/gif/gif_nlp.gif'
import gGan from '../assets/gif/gif_gan.gif'
import gobj from '../assets/gif/gif_obj.gif'
import learningP1 from '../assets/images/learningP.png'
import learningP2 from '../assets/images/learningP2.png'
import learningP3 from '../assets/images/learningP3.png'
import learningP4 from '../assets/images/learningP4.png'

const courseData = [
  {
    title: ' Computer Vision',
    subtitle: 'Learn the basics of Machine Learning. Dive deep into Deep Learning for Computer Vision Using TensorFlow 2. Master how to build, train, evaluate, test and Deploy Deep Learning Models. Understanding Key MLOps concepts. Going from beginner to solving real world problems efficiently!',
    hours: 'More than 120 lessons amounting to more than 50 hours + of video',
    courseContent: [
      'Practice based lessons',
      'More than 120 lessons amounting to more than 50 hours + of video',
      'Access to exclusive Neuralearn community',
      'Full Lifetime access to all courses and resources',
      'Access on mobile and TV'
    ],
    prerequis: [
      ' Basic Math',
      'Basic Python Experience [Check out this free Resource]',
      'A computer with an internet connection'
    ],
    prerequisVideo: 'https://www.youtube.com/playlist?list=PL2L83ZcMO-5Ow9Xi-dJqoRc_aZ7g-Ae0V',
    videoLink: 'https://storage.googleapis.com/sincere-signal-267510.appspot.com/neuralearn/neuralearn_remake/object_detection/0-intro/welcome-computer-vision.mp4',

    whatYouLearn: [
      'Mastery of the fundamentals of Machine Learning and The Machine Learning Developmment Lifecycle.',
      'Learning how to work with Tensorflow Tensors, Variables and Methods',
      'Understanding of simple ML algorithms like Linear Regression, Logistic Regression and Neural Networks.',
      'Convolutional Neural Networks, Modern ConvNets, and Vision Transformers with TensorFlow 2.',
      'Malaria diagnosis with Tensorflow 2',
      'People counting with Tensorflow 2',
      'YOLO Object detection from scratch with Tensorflow 2',
      'Image generation with Generative Adversarial neural networks ',
      'Deploying a Deep Learning Model to the Cloud',
      'Advanced Usage of Tensorflow 2',
      'Working with Hugggingface',
      'Mastery of Transfer Learning',
      'MLOps with Wandb'
    ],
    gif: gcomputerVision,
    buyCourse: 'https://neuralearn.podia.com/deep-learning-for-computer-vision/buy',
    learningPath: learningP1
  },
  {
    title: ' Image Generation',
    subtitle: 'Learn the basics of Generative Adversarial Neural Networks. Dive deep into building GANs and Diffusion Models with TensorFlow 2. Master how to generate high quality images with modern GANs and Diffusion models. Going from beginner to solving real world problems efficiently!',
    hours: 'More than 50 lessons amounting to more than 15 hours + of video',
    courseContent: [
      'Practice based lessons',
      'More than 50 lessons amounting to more than 15 hours + of video',
      'Access to exclusive Neuralearn community',
      'Full Lifetime access to all courses and resources',
      'Access on mobile and TV'
    ],
    prerequis: [
      ' Basic Math',
      'Basic Python Experience [Check out this free Resource]',
      'A computer with an internet connection'
    ],
    prerequisVideo: 'https://www.youtube.com/playlist?list=PL2L83ZcMO-5Ow9Xi-dJqoRc_aZ7g-Ae0V',
    videoLink: 'https://storage.googleapis.com/sincere-signal-267510.appspot.com/neuralearn/neuralearn_remake/gan/0-intro/welcome-gan.mp4',

    whatYouLearn: [
      'Image generation with variational autoencoders.',
      'Building DCGANs with Tensorflow 2',
      'More stable training with Wasserstein GANs in Tensorflow 2',
      'Generating high quality images with ProGANs',
      'Building mask remover with CycleGANs',
      'Image superresolution with SRGANs',
      'Advanced Usage of Tensorflow 2 ',
      'Image generation with Diffusion models'
    ],
    gif: gGan,
    buyCourse: 'https://neuralearn.podia.com/image-generation-with-gans-and-diffusion-models/buy',
    learningPath: learningP3
  },
  {
    title: ' Natural Language Processing',
    subtitle: 'Learn the basics of Machine Learning. Dive deep into Deep Learning for Natural Language Processing using TensorFlow 2 and Huggingface. Master how to build, train, evaluate, test and Deploy Deep Learning Models. Understanding Key MLOps concepts. Going from beginner to solving real world problems efficiently!',
    hours: 'More than 100 lessons amounting to more than 40 hours + of video',
    courseContent: [
      'Practice based lessons',
      'More than 100 lessons amounting to more than 40 hours + of video',
      'Access to exclusive Neuralearn community',
      'Full Lifetime access to all courses and resources',
      'Access on mobile and TV'
    ],
    prerequis: [
      ' Basic Math',
      'Basic Python Experience [Check out this free Resource]',
      'A computer with an internet connection'
    ],
    prerequisVideo: 'https://www.youtube.com/playlist?list=PL2L83ZcMO-5Ow9Xi-dJqoRc_aZ7g-Ae0V',
    videoLink: 'https://storage.googleapis.com/sincere-signal-267510.appspot.com/neuralearn/neuralearn_remake/nlp/0-intro/welcome-nlp.mp4',

    whatYouLearn: [
      'Mastery of the fundamentals of Machine Learning and The Machine Learning Developmment Lifecycle',
      'Learning how to work with Tensorflow Tensors, Variables and Methods',
      'Understanding of simple ML algorithms like Linear Regression, Logistic Regression and Neural Networks.',
      'Recurrent Neural Networks, Attention Networks and Transformers with TensorFlow 2.',
      'Neural Machine Translation with Tensorflow 2',
      'Sentiment analysis with Tensorflow 2 ',
      'Deploying a Deep Learning Model to the Cloud',
      'Mastery of Transfer Learning',
      'Extractive question answering with Huggingface and Tensorflow 2',
      'Ecommerce search engine with Huggingface and Tensorflow 2',
      'Lyrics generation with Huggingface and Tensorflow 2',
      'Grammatical Error Correction with Huggingface and Tensorflow 2',
      'Chatbots with Huggingface and Tensorflow 2',
      'Speech recognition with Tensorflow 2'
    ],
    gif: gnlp,
    buyCourse: 'https://neuralearn.podia.com/3cfeb171-a5fc-4f9f-9c73-fce5021f045a/buy',
    learningPath: learningP2
  },
  {
    title: ' Object Detection',
    subtitle: 'Learn the basics of Machine Learning. Dive deep into Deep Learning for Object Detection with YOLOX. Master how to prepare data, train, evaluate, test and Deploy Object Detection Models. Understanding Key MLOps concepts. Going from beginner to solving real world problems efficiently!',
    hours: 'More than 30 lessons amounting to more than 15 hours + of video',
    courseContent: [
      'Practice based lessons',
      'More than 30 lessons amounting to more than 15 hours + of video',
      'Access to exclusive Neuralearn community',
      'Full Lifetime access to all courses and resources',
      'Access on mobile and TV'
    ],
    prerequis: [' Basic Math', 'A computer with an internet connection'],
    prerequisVideo: '',
    videoLink: 'https://storage.googleapis.com/sincere-signal-267510.appspot.com/neuralearn/neuralearn_remake/computer_vision/0-intro/welcome-object-detection.mp4',

    whatYouLearn: [
      'Mastery of the fundamentals of Machine Learning and The Machine Learning Developmment Lifecycle.',
      'Understanding different object detection algorithms',
      'Learning how YOLO and YOLOX work',
      'Preparing dataset for training object detection model',
      'Wandb integration',
      'Building APIs with Fastapi',
      'Integration with C++',
      'Deploying a Deep Learning Model to the Cloud',
      'Mastery of Transfer Learning'
    ],
    gif: gobj,
    buyCourse: 'https://neuralearn.podia.com/deep-learning-for-natural-language-processing-with-tensorflow-and-huggingface/buy',
    learningPath: learningP4

  }
]

export default courseData
