// import ReactPlayer from "react-player";
import VideoPlayer from "react-video-js-player";
import ReactGA from "react-ga";
import "./CourseComponent.css";
import a1 from "../../assets/images/a1.png";
import a2 from "../../assets/images/a2.png";
import a3 from "../../assets/images/a3.png";
import a4 from "../../assets/images/a4.png";
import a5 from "../../assets/images/a5.png";
import a6 from "../../assets/images/a6.png";

// import learnPathImg from "../../assets/images/learningP.png";
import courseData from "../../constants/courseData";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ObjectDetetion from "../learningPath/ObjectDetetion";
import NaturalLnguage from "../learningPath/NaturalLanguage";
import DeepLearning from "../learningPath/DeepLearning";
import GAN from "../learningPath/GAN";
const CourseComponent = () => {
  useEffect(() => {
    // non interraction
    ReactGA.pageview(window.location.pathname);
  }, []);

  const { courseId } = useParams();
  const [courseContent, setCourseContent] = useState();

  let id;
  // useEffect(() => {
  if (courseId === "computer_vision") {
    id = 0;
    // setCourseContent(<DeepLearning />);
  } else if (courseId === "image_generation") {
    id = 1;
    // setCourseContent(<GAN />);
  } else if (courseId === "natural_language_processing") {
    id = 2;
    // setCourseContent(<NaturalLnguage />);
  } else if (courseId === "object_detection") {
    id = 3;
    // setCourseContent(<ObjectDetetion />);
  }
  // }, []);
  useEffect(() => {
    if (courseId === "computer_vision") {
      setCourseContent(<DeepLearning />);
    } else if (courseId === "image_generation") {
      setCourseContent(<GAN />);
    } else if (courseId === "natural_language_processing") {
      setCourseContent(<NaturalLnguage />);
    } else if (courseId === "object_detection") {
      setCourseContent(<ObjectDetetion />);
    }
  }, [courseId]);

  //
  //
  //
  //

  const {
    title,
    videoLink,
    gif,
    subtitle,
    prerequisVideo,
    // whatYouLearn,
    hours,
    buyCourse,
    learningPath,
  } = courseData[id];
  // console.log(whatYouLearn);
  let analyData = courseData[id];

  // interraction
  ReactGA.event({
    category: analyData.title,
    action: "test action",
    label: "test label",
    value: 99,
  });

  return (
    <>
      <>
        <div className=" master-deep-course ">
          <span className="master mobile-design">Deep Learning for &nbsp;</span>{" "}
          {title}
        </div>
        <div className="gainContainer">{subtitle}</div>
        <div className="course-img">
          <img src={gif} alt="" />
        </div>
        <div className="learning-now-container">
          <p>Take the Bold Step</p>
          <div className="learning-now-btn">
            <a href={buyCourse}>Start Learning Now !</a>
          </div>
        </div>
        <div className="courses-details-container">
          <div className="courses-details-container1">
            <h1>This Course Includes</h1>
            <div className="links1">
              <img src={a1} alt="" />
              Practice based lessons
            </div>
            <div className="links1">
              <img src={a2} alt="" />
              {hours}
            </div>
            <div className="links1">
              <img src={a3} alt="" />
              Access to exclusive Neuralearn community
            </div>
            <div className="links1">
              <img src={a4} alt="" />
              Full Lifetime access to all courses and resources
            </div>
            <div className="links1">
              <img src={a5} alt="" />
              Access on mobile and TV
            </div>
            <h1>Prerequiscites</h1>
            <div>
              <img src={a6} alt="" />
              Basic Math
            </div>
            {courseId !== "3" && (
              <div>
                <img src={a6} alt="" />
                Basic Python Experience [{" "}
                <a href={prerequisVideo}>Check out this free Resource</a> ]{" "}
                <br />
              </div>
            )}
            <div>
              <img src={a6} alt="" />A computer with an internet connection
            </div>
          </div>
          <div className="courses-details-container1 destop-video">
            {/* <ReactPlayer
              // controls="true"
              url={videoLink}
            /> */}
             
            <VideoPlayer
              width="600px"
              height="350px"
              src={videoLink}
              controls
            />
          </div>
          <div className="course-details-mobile">
            <VideoPlayer
              width="350px"
              // height="350px"
              src={videoLink}
              controls
            />
          </div>
        </div>
        {/* <div className="courses-details-container2">
          <div className="courses-details2">
            <h1 className="learn-topic">What You'll Learn</h1>
            {whatYouLearn.slice(0, 6).map((item, i) => (
              <div key={i}>
                <img src={a6} alt="" />
                {item}
              </div>
            ))}
          </div>
          <div className="courses-details2 course-part2">
            {whatYouLearn.slice(6).map((item, i) => (
              <div key={i}>
                <img src={a6} alt="" />
                {item}
              </div>
            ))}
          </div>
        </div> */}
        {/* 648 */}

        {/* learnimg path image */}
        <div className="learning-path-img-container ">
          <div className="learng-path-avatar">
            <img src={learningPath} alt="learning path" />
          </div>
        </div>
        <div className="learning-path-html">{courseContent}</div>
      </>
    </>
  );
};

export default CourseComponent;
