import React from "react";
import "./card.css";
const Card = ({ name, content }) => {
  return (
    <div>
      <div className="card_slide">
        <div className="card__title">{name}</div>
        <div className="card__content">{content}</div>
      </div>
    </div>
  );
};

export default Card;
