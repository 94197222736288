import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion"
import logo from "../../assets/images/logo2.png";
import "../assets/styles/Navbar.scss";
export default function Navbar() {
  const [toggle, setToggle] = useState(false)
  const [toggleDropdown, setToggleDropdown] = useState(false)
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-dark">
        <div className="container-fluid navpadding">
          <Link className="navbar-brand " to="/">
            <img width="30px" src={logo} alt="logo" />
            <span className="neuralearn-logo">neuralearn</span>
          </Link>

          <button
            className="navbar-toggler"


          >
             <div className='toggle-menu-btn'>
             <HiMenuAlt4 onClick={() => setToggle(true)} />
             </div>
            {/* <span class="navbar-toggler-icon"></span> */}
          </button>
          <div
            className="collapse navbar-collapse nav-ul"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Courses
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    {/* <a className="dropdown-item" href="/">
                      Course One
                    </a> */}
                    <Link className="dropdown-item" to="/course/computer_vision">Computer vision</Link>
                  </li>
                  <li>
                    {/* <a className="dropdown-item" href="/">
                      Course Two
                    </a> */}
                    <Link className="dropdown-item" to="/course/natural_language_processing" >
NLP

                </Link>
                  </li>
                  <li>
                    {/* <a className="dropdown-item" href="/">
                      Course Three
                    </a> */}
                    <Link to="/course/image_generation" className="dropdown-item">
                    Image Generation
                </Link>
                  </li>
                  <li>

{
  /* <a className="dropdown-item" href="/">
                        Course Four
                      </a> */
}

                    <Link className="dropdown-item" to='/course/object_detection'> Object Detection
</Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link " style={{ width:'120px' }} href="mailto:team@neuralearn.ai">
               
                Contact Us
                </a>
              </li>

{
/* <li className="nav-item">
                <a className="nav-link" href="/">
<div className='  ' style={{ color: 'white' }}>
  Contact Us
</div>

                </a>
              </li> */

}

            </ul>
          </div>
        </div>
        <div className="app__navbar-menu">
      
        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul className="app__navbar-links">
            <li><a  href="mailto:team@neuralearn.ai" onClick={() => setToggle(false)}>Contact Us</a></li>
            {/* <li><a href={`hht`} onClick={() => setToggle(false)}>Sign up</a></li> */}
            <li onClick={() => setToggleDropdown(!toggleDropdown)}><li>Courses</li></li>

            {toggleDropdown && <ul className='mobile-dropdown'>
            <li><Link to="/course/computer_vision" onClick={() => setToggle(false)}>Computer Vision</Link></li>
            <li><Link to="/course/natural_language_processing" onClick={() => setToggle(false)}>NLP</Link></li>
            <li><Link to="/course/image_generation" onClick={() => setToggle(false)}>Image Generation</Link></li>
            <li><Link to='/course/object_detection' onClick={() => setToggle(false)}>Object Detection</Link></li>
           
            </ul>}
           
            </ul>
          </motion.div>
        )}
      </div>
      </nav>
    </>
  );
}
