import React from "react";
import npl1 from "../assets/nl/npl1.svg";
import npl2 from "../assets/nl/npl2.svg";
import npl4 from "../assets/nl/npl4.svg";
import npl3 from "../assets/nl/npl3.svg";
import npl5 from "../assets/nl/npl5.svg";
import npl6 from "../assets/nl/npl6.svg";
import npl7 from "../assets/nl/npl7.svg";
import npl8 from "../assets/nl/npl8.svg";
import npl9 from "../assets/nl/npl9.svg";
import npl10 from "../assets/nl/npl10.svg";
import npl11 from "../assets/nl/npl11.svg";
import npl12 from "../assets/nl/npl12.svg";
import npl13 from "../assets/nl/npl13.svg";
import npl14 from "../assets/nl/npl14.svg";
import npl15 from "../assets/nl/npl15.svg";
import npl16 from "../assets/nl/npl16.svg";
import npl17 from "../assets/nl/npl17.svg";
import npl18 from "../assets/nl/npl18.svg";
import npl19 from "../assets/nl/npl19.svg";
import npl20 from "../assets/nl/npl20.svg";
import npl21 from "../assets/nl/npl21.svg";
import npl22 from "../assets/nl/npl22.svg";
const NaturalLnguage = () => {
  return (
    <div>
      <div>
        <div className="learningPathbody">
          <section className="learning-path-html">
            <h2 className="title my-5">Learning Path</h2>

            <section className="path">
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">Introduction</h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl1} alt="intro" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Welcome</p>
                    </div>
                    <div className="lesson">
                      <p>General Introduction</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Applications of Deep Learning in Computer Vision</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Course Structure</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        Tensors and Variables
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl2} alt="intro" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Basics</p>
                    </div>
                    <div className="lesson">
                      <p>Initialization and Casting</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Indexing</p>
                    </div>
                    <div className="lesson">
                      <p>Maths Operations</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Linear Algebra Operations</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Common TensorFlow Functions</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Ragged Tensors</p>
                    </div>
                    <div className="lesson">
                      <p>Sparse Tensors</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>String Tensors</p>
                    </div>
                    <div className="lesson">
                      <p>Variables</p>
                    </div>
                    <div className="lesson">
                      <p>Loading and Saving Models</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        Building Neural Networks with TensorFlow{" "}
                        <span style={{ color: "orange" }}>
                          [Car Price Prediction]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl3} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Task Understanding</p>
                    </div>
                    <div className="lesson">
                      <p>Data Preparation</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Linear Regression Model</p>
                    </div>

                    <div className="lesson">
                      <p>Error Sanctioning</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Training and Optimization</p>
                    </div>

                    <div className="lesson">
                      <p>Performance Measurement</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Validation and Testing</p>
                    </div>

                    <div className="lesson">
                      <p>Corrective Measures</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        Text Preprocessing for Sentiment Analysis
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl4} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Understanding Sentiment Analysis</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Text Standardization</p>
                    </div>
                    <div className="lesson">
                      <p>Tokenization</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>One-hot encoding and Bag of Words</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>
                        Term frequency - Inverse Document frequency (TF-IDF)
                      </p>
                    </div>
                    <div className="lesson">
                      <p>Embeddings</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        Sentiment Analysis with Recurrent neural networks
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl5} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>How Recurrent neural networks work</p>
                    </div>

                    <div className="lesson">
                      <p>Data Preparation</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Building and training RNNs</p>
                    </div>
                    <div className="lesson">
                      <p>Advanced RNNs (LSTM and GRU)</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>1D Convolutional Neural Network</p>
                    </div>
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Sentiment Analysis with transfer learning
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl6} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>UUnderstanding Word2vec</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Integrating pretrained Word2vec embeddings</p>
                    </div>

                    <div className="lesson">
                      <p>Testing</p>
                    </div>

                    <div className="lesson">
                      <p>Visualizing embeddings</p>
                    </div>
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Neural Machine Translation with Recurrent Neural
                        Networks
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl7} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Understanding Machine Translation</p>
                    </div>

                    <div className="lesson">
                      <p>Data Preparation</p>
                    </div>

                    <div className="lesson">
                      <p>Building, training and testing Model</p>
                    </div>

                    <div className="lesson">
                      <p>Understanding BLEU score</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Coding BLEU score from scratch</p>
                    </div>
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Neural Machine Translation with Attention
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl8} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Understanding Bahdanau Attention</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Building, training and testing Bahdanau Attention</p>
                    </div>
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Neural Machine Translation with Transformers
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl9} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Understanding Transformer Networks</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Building, training and testing Transformers</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Building Transformers with Custom Attention Layer</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Visualizing Attention scores</p>
                    </div>
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Sentiment Analysis with Transformers
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl10} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Sentiment analysis with Transformer encoder</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Sentiment analysis with LSH Attention</p>
                    </div>
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Transfer Learning and Generalized Language Models
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl11} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Understanding Transfer Learning</p>
                    </div>

                    <div className="lesson">
                      <p>Ulmfit</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Gpt</p>
                    </div>
                    <div className="lesson">
                      <p>Bert</p>
                    </div>
                    <div className="lesson">
                      <p>Albert</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Gpt2</p>
                    </div>
                    <div className="lesson">
                      <p>Roberta</p>
                    </div>
                    <div className="lesson">
                      <p>T5</p>
                    </div>
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Sentiment Analysis with Deberta in Huggingface
                        transformers
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl12} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <br />
                    <br />
                    <div className="lesson">
                      <p>Data Preparation</p>
                    </div>

                    <br />
                    <br />
                    <div className="lesson">
                      <p>Building,training and testing model</p>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Model Deployment{" "}
                        <span
                          style={{
                            color: "orange",
                          }}
                        >
                          [Human Emotions Detection]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl13} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Understanding Quantization</p>
                    </div>

                    <div className="lesson">
                      <p>Conversion from to Onnx Model</p>
                    </div>
                    <br />

                    <div className="lesson">
                      <p>Quantization of Onnx Model</p>
                    </div>

                    <div className="lesson">
                      <p>Quantization Aware Training</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Conversion to TensorflowLite model</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>How APIs work</p>
                    </div>
                    <div className="lesson">
                      <p>Building API with Fastapi</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Deploying API to the Cloud</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Load Testing API</p>
                    </div>
                    <br />
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Intent Classification with Deberta in Huggingface
                        transformers
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl14} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Problem Understanding and Data Preparation</p>
                    </div>

                    <br />
                    <br />
                    <div className="lesson">
                      <p>Building,training and testing model</p>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Intent Classification with Deberta in Huggingface
                        transformers
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl15} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Problem Understanding and Data Preparation</p>
                    </div>

                    <br />
                    <br />
                    <div className="lesson">
                      <p>Building,training and testing model</p>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Neural Machine Translation with T5 in Huggingface
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl16} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Dataset Preparation</p>
                    </div>

                    <br />
                    <br />
                    <div className="lesson">
                      <p>Building,training and testing model</p>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Extractive Question Answering with Longformer in
                        Huggingface transformers
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl17} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Dataset Preparation</p>
                    </div>

                    <br />
                    <br />
                    <div className="lesson">
                      <p>Building,training and testing model</p>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Ecommerce search engine with Sentence transformers
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl18} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Dataset Preparation</p>
                    </div>

                    <br />
                    <br />
                    <div className="lesson">
                      <p>Building,training and testing model</p>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Lyrics Generator with GPT2 in Huggingface transformers
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl19} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Dataset Preparation</p>
                    </div>

                    <br />
                    <br />
                    <div className="lesson">
                      <p>Building,training and testing model</p>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Grammatical Error Correction with T5 in Huggingface
                        transformers
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl20} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Dataset Preparation</p>
                    </div>

                    <br />
                    <br />
                    <div className="lesson">
                      <p>Building,training and testing model</p>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Elon Musk Bot with BlenderBot in Huggingface
                        transformers
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl21} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Dataset Preparation</p>
                    </div>

                    <br />
                    <br />
                    <div className="lesson">
                      <p>Building,training and testing model</p>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">Speech recognition</h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={npl22} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p> Understanding Automatic Speech Recognition</p>
                    </div>

                    <br />
                    <br />
                    <div className="lesson">
                      <p>LJ- Speech Dataset</p>
                    </div>
                    <div className="lesson">
                      <p> Fourier Transform</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p> Short Time Fourier Transform</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p> Conv - CTC Model</p>
                    </div>
                  </div>
                </div>
              </article>
            </section>
          </section>

          <div className="mt-5 ms-5" style={{ paddingTop: "1rem" }}>
            <h2 className="title mt-5">Learning Never Ends!!!</h2>
            <h2
              className="mt-3"
              style={{
                color: "#D16500",
                fontSize: "2rem",
              }}
            >
              This Course Will Constantly be Updated
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NaturalLnguage;
