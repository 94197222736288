import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles.css";
import Card from "./card/Card";
import { testimonials } from "./data";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 300 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const TestimonialSlider = () => {
  return (
    <div
      className="parent"
      style={{
        color: "#D16500",
        fontSize: "24px",
        fontWeight: "700",
        textAlign: "center",
        padding: "10px 0px",
      }}
    >
      <div>What other Learners Say</div>
      <Carousel
        // customLeftArrow={<CustomLeftArrow />}
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
        {testimonials.map((testimomil, index) => {
          return (
            <div className="slider" key={index}>
              <Card name={testimomil.name} content={testimomil.content} />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
export default TestimonialSlider;
