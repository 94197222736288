import "./App.css";
import Home from "./pages/home/Home";
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import Course from "./pages/courses/Course";
import ReactGA from "react-ga";
import { useLayoutEffect } from "react";
import NotFound from "./pages/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Draft from "./Draft/Draft";
const TRACKING_ID = "UA-224344759-2"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
  return (
    <div className="App">
      <BrowserRouter>
        <Wrapper>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/course/:courseId" element={<Course />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/draft" element={<Draft />} />
          </Routes>
          <ToastContainer />
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}
export default App;
