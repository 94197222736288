import React, { useEffect } from "react";
import BannerComponent from "../../components/banner/BannerComponent";
import Footer from "../../components/footer/Footer";
import Built from "../../components/homeComponents/Built";
import Homehead from "../../components/homeComponents/Homehead";
import Navbar from "../../components/navbar/Navbar";
import ReactGA from "react-ga";
import "./Home.css";

import Discount from "../../components/dicount/Discount";

const Home = () => {
  useEffect(() => {
    // non interraction
    ReactGA.pageview(window.location.pathname);
  }, []);
  ReactGA.event({
    category: "",
    action: "test action",
    label: "test label",
    value: "",
  });

  return (
    <div className="home__wrapper">
      <Discount />
      <Navbar />
      <BannerComponent />

      <Homehead />
      <Built />

      <Footer />
    </div>
  );
};

export default Home;
