import React, { useEffect } from "react";
import "./built.css";
import Carousel from "framer-motion-carousel";
import nImage from "../../assets/images/image1.png";
import image6 from "../assets/images/image6.png";
import image66 from "../../assets/images/image6.png";
import image7 from "../../assets/images/image7.png";
import Carosel from "./carrosel/Carosel";
import Carosel2 from "./carrosel/Carosel2";
import Carosel3 from "./carrosel/Carosel3";
import Carosel4 from "./carrosel/Carosel4";
import { Link } from "react-router-dom";
import MobileCaroselList from "./carrosel/mobile/MobileCarosel1";
import TestimonialSlider from "../testimonialSlider/TestimonialSlider";

export default function Built() {
  const caroselList = [<Carosel />, <Carosel3 />, <Carosel2 />, <Carosel4 />];
  useEffect(() => {
    const elements = Array.from(document.getElementsByClassName("carosel-box"));
    const elmt = Array.from(elements);
    let arrow1 = elmt[0].children[0].children[4];

    let arrow2 = elmt[0].children[0].children[5];
    let span = elmt[0].children[0].children[6];
    span.className = "spanBox";
    span.style =
      "position: absolute; bottom: 10px; left: 85%; transform: translateX(-50%);";

    let span1 = elmt[0].children[0].children[6].children[0];
    let span2 = elmt[0].children[0].children[6].children[1];
    let span3 = elmt[0].children[0].children[6].children[2];
    let span4 = elmt[0].children[0].children[6].children[3];
    span1.className = "carosel-button1";
    span2.className = "carosel-button2";

    span3.className = "carosel-button3";
    span4.className = "carosel-button4";

    arrow1.className = "arrow";
    arrow2.className = "arrow";

    // Carosel mobile edit
    const mobileEments = Array.from(
      document.getElementsByClassName("carosel-box-mobile")
    );
    const mobileElmt = Array.from(mobileEments);
    let mobileArrow1 = mobileElmt[0].children[0].children[4];

    let mobileArrow2 = mobileElmt[0].children[0].children[5];
    let mobileSpan = mobileElmt[0].children[0].children[6];
    mobileSpan.className = "spanBox";
    mobileSpan.style =
      "position: absolute; bottom: 0px; left: 50%; transform: translateX(-50%);";

    let m_span1 = mobileElmt[0].children[0].children[6].children[0];

    let m_span2 = mobileElmt[0].children[0].children[6].children[1];
    let m_span3 = mobileElmt[0].children[0].children[6].children[2];
    let m_span4 = mobileElmt[0].children[0].children[6].children[3];
    m_span1.className = "carosel-button1";
    m_span2.className = "carosel-button2";

    m_span3.className = "carosel-button3";
    m_span4.className = "carosel-button4";
    mobileArrow1.className = "arrow";
    mobileArrow2.className = "arrow";

    // Carosel mobile edit
  }, []);

  return (
    <>
      <div className="carosel-desktop">
        <div className="carosel-box" id="caro-box">
          <Carousel interval="7000" autoPlay="false" loop="false">
            {caroselList.map((item, i) => (
              <div
                key={i}
                style={{
                  width: "100%",
                  height: "100%",
                  // backgroundColor: ,
                }}
              >
                {caroselList[i]}
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <div className="carosel-mobile">
        <div className="carosel-box-mobile">
          <Carousel interval="7000" autoPlay="false" loop="false">
            {MobileCaroselList.map((item, i) => (
              <div
                key={i}
                style={{
                  width: "100%",
                  height: "100%",
                  // backgroundColor: ,
                }}
              >
                {MobileCaroselList[i]}
              </div>
            ))}
          </Carousel>
        </div>
      </div>

      <div className="premium-course_container container-lg ">
        <div>
          <div className=" premium-header">
            <span className="premium"> Premium</span> Courses
          </div>
        </div>
        <div className=" pre-container ">
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-md-6 deepImgContainer ">
              <img className="deepImg" src={nImage} alt="" />
            </div>

            <div className="col-10 col-md-5 m-4  text-center">
              <h1 className="h1 fw-bold deeplearningH1-mobile ">
                Deep Learning for Computer vision{" "}
              </h1>
              <h1 className="text-center h3 price">
                <span className="oldPrice">$ 99</span>
                <span>$49</span>
              </h1>
              <div className="startContainer">
                <a
                  className="startBtn"
                  href="https://neuralearn.podia.com/deep-learning-for-computer-vision/buy"
                >
                  start
                </a>
                <Link to="/course/computer_vision">Learn More {">"}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-3">
        <div className="row g-2 me-auto d-flex justify-content-center">
          <div className="col-12 col-md-6 ">
            <div className="card">
              <div className="h2 text-center mt-5 p-3">
                Deep Learning for <br />
                Natural Language
                <br />
                Processing
              </div>
              <h1 className="text-center h3 price my-2">
                <span className="oldPrice">$ 99</span>
                <span>$49</span>
              </h1>
              <div className="startContainer my-2">
                <a
                  className="startBtn1"
                  href="https://neuralearn.podia.com/3cfeb171-a5fc-4f9f-9c73-fce5021f045a/buy"
                >
                  start
                </a>

                <Link
                  to="/course/natural_language_processing"
                  className="learnMore"
                >
                  Learn More {">"}
                </Link>
              </div>
              <div className="cardImg">
                <img src={image7} alt="" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card">
              <div className="h2 text-center mt-5 p-3">
                Deep Learning for Image Generation with GANs and Diffusion
                Models
              </div>
              <h1 className="text-center h3 price my-2">
                <span className="oldPrice">$ 99</span>
                <span>$49</span>
              </h1>
              <div className="startContainer my-2">
                <a
                  className="startBtn1"
                  href="https://neuralearn.podia.com/image-generation-with-gans-and-diffusion-models/buy"
                >
                  start
                </a>

                <Link to="/course/image_generation" className="learnMore">
                  Learn More {">"}
                </Link>
              </div>
              <div className="cardImg">
                <img src={image6} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container objectContainer py-5 mb-5">
        <div className="row g-2 d-flex justify-content-center">
          <div className="col-12 col-md-6 deepImgContainer2">
            <img className="deepImg" src={image66} alt="" />
          </div>

          <div className="col-12 col-md-6">
            <div className="card card1">
              <h1 className="h3 text-center fw-bold">
                {" "}
                Deep Learning for <br /> Obect Detection
              </h1>
              <h1 className="text-center h3 price">
                <span className="oldPrice">$ 99</span>
                <span>$49</span>
              </h1>
              <div className="startContainer">
                <a
                  className="startBtn"
                  href="https://neuralearn.podia.com/deep-learning-for-natural-language-processing-with-tensorflow-and-huggingface/buy"
                >
                  start
                </a>

                <Link to="/course/object_detection">
                  {" "}
                  Learn More
                  {">"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TestimonialSlider />
    </>
  );
}
