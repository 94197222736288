import cf1 from '../../../../assets/images/cf1.png'
import ct1 from '../../../../assets/images/ct1.png'
import './MobileCarosel1.css'
import c1 from '../../../../assets/images/c1.png'
import image2 from '../../../../assets/images/image5.png'

const MobileCaroselList = [
  <div className='mobile-carosel container-lg mb-2'>
    <div className='row d-flex justify-content-center'>
      <div className='col-10'>
        <div className='m-carosel-heading h4 mb-3'>
          Built And Deployed Usable Computer Vision Solutions
        </div>
        <div className='m-carosel-image'>
          <img src={image2} alt='' className='img-fluid' />
        </div>
      </div>
    </div>
  </div>,
  <div className='mobile-carosel container-lg mb-2'>
    <div className='row d-flex justify-content-center'>
      <div className='col-10'>
        <div className='m-carosel-heading h4 mb-3'>
          Built and Deployed State of art Object detection Models
        </div>
        <div className='m-carosel-image'>
          <img src={cf1} alt='' className='img-fluid' />
        </div>
      </div>
    </div>
  </div>,
  <div className='mobile-carosel container-lg mb-2'>
    <div className='row d-flex justify-content-center'>
      <div className='col-10'>
        <div className='m-carosel-heading h4 mb-3'>
          Generated Images with GANs and Diffusion Models
        </div>
        <div className='m-carosel-image'>
          <img src={ct1} alt='' className='img-fluid' />
        </div>
      </div>
    </div>
  </div>,
  <div className='mobile-carosel container-fluid mb-2'>
    <div className='row d-flex justify-content-center'>
      <div className='col-10'>
        <div className='m-carosel-heading h4 mb-3'>
          Built and Deployed Modern NLP Solutions
        </div>
        <div className='m-carosel-image'>
          <img src={c1} alt='' className='img-fluid' />
        </div>
      </div>
    </div>
  </div>
]

export default MobileCaroselList
