import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./Discount.css";

const Discount = () => {
  const [scrolledToDiv, setScrolledToDiv] = useState("discount-container-hide");

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };
    if (scrollPosition >= 100) {
      setScrolledToDiv("discount-container");
    } else if (scrollPosition === 0) {
      setScrolledToDiv("discount-container-hide");
    }
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);
  return (
    <div className={`${scrolledToDiv}`}>
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        {" "}
        Seize this opportunity and start learning!!!{" "}
      </span>
      <span
        style={{
          fontWeight: "lighter",
        }}
      >
        Our courses are now much more affordable
      </span>
    </div>
  );
};

export default Discount;
