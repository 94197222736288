import { Link } from 'react-router-dom'

export default function NotFound () {
  return (
    <div className='notFound'>
      <h1>Oops! You seem to be lost.</h1>
      <h3>404 Page Not Found</h3>
      <Link to='/' className='btn nf-btn'> back to Home
      </Link>
    </div>
  )
}
