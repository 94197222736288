import React from 'react'

import './Carosel2.css'
import ct1 from '../../../assets/images/ct1.png'
import ct2 from '../../../assets/images/ct2.png'
import ct3 from '../../../assets/images/ct3.png'
import ct4 from '../../../assets/images/ct4.png'

const Carosel2 = () => {
  return (
    // <motion.div whileInView={{ x: [-100, 0], opacity: [0, 1] }} transition={{ duration: 1.5 }}>
    <div className='builtContainer'>
      <div className='main-built'>
        <div className=' built1'>
          <div className=' buit-text2 mb-3'>
            Generated Images with
            <br /> GANs & Diffusion Models
          </div>
          <div>
            <img className='ct1' src={ct1} alt='' />
          </div>
        </div>
        <div className=''>
          <div className=''>
            <div>
              <img className='ct2' src={ct2} alt='' />
            </div>
            <div className='image3Container'>
              <img className='ct3' src={ct3} alt='' />
            </div>
          </div>
        </div>
        <div className='image4-container'>
          <img className='ct4' src={ct4} alt='' />
          <div className='bubbles2'>
            <div className='buble1'></div>
            <div className='buble2'></div>
            <div className='buble3'></div>
            <div className='buble4'></div>
          </div>
        </div>
      </div>
    </div>
  // </motion.div>
  )
}

export default Carosel2
