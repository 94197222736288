import TestimonialSlider from "../components/testimonialSlider/TestimonialSlider";

import React from "react";

export default function Draft() {
  return (
    <div>
      <TestimonialSlider />
    </div>
  );
}
