import React, { useState } from "react";
import "./Footer.css";
import logo from "../../assets/images/footerLogo.png";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const parameter = useParams();
  const paramId = parameter.courseId;
  const [userEmail, setUserEmail] = useState("");
  const baseURL = "https://kwiklik.herokuapp.com/email/list/";
  const [isPending, setIsPending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPending(true);
    try {
      const res = await fetch(baseURL, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userEmail,
        }),
      });

      toast.success("Thanks for joining the community", {
        position: "top-center",
        autoClose: "5000",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setIsPending(false);
      setUserEmail("");

      console.log(res);
    } catch (err) {
      console.log(err);
      toast.error("Something wrong happened. Please try again later", {
        position: "top-center",
        autoClose: "5000",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      setIsPending(false);
    }
  };

  return (
    <div className="footer">
      {/* {!paramId && (
        <div className="footer-top">
          <h1 className="f-heading">Stay Updated</h1>
          <div className="f-details">
            Join our community and learn about recent advances in AI and how to
            apply them in practice.
          </div>
          <form onSubmit={handleSubmit}>
            <div className="f-input">
              <label>
                <span>email address</span>
                <input
                  type="email"
                  required
                  value={userEmail}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                />
              </label>
            </div>
            <div className="join-container">
              <button className="join-btn" type="submit">
                {isPending ? "Joining" : "Join"}
              </button>
            </div>
          </form>
        </div>
      )} */}
      <div className="f-container">
        <div className="f-bootom-container">
          <div className="container f-logo-container">
            <a className="navbar-brand  f-logo" href="/">
              <img width="30px" src={logo} alt="logo" />
              <span className="logo">neuralearn</span>
            </a>
          </div>

          <div className="footer-bottom container-fluid">
            <div className="courses course1">
              <h3>Courses</h3>
              <div>
                <Link to="/course/computer_vision">
                  Deep Learning for Computer Vision{" "}
                </Link>
                <a href="/"> </a>
                <Link to="/course/natural_language_processing">
                  Deep Learning for Natural Language Processing
                </Link>

                <Link to="/course/image_generation">
                  Deep Learning for Image Generation with GANs and Diffusion
                  Models
                </Link>
                <Link to="/course/object_detection">
                  {" "}
                  Deep Learning for Object Detection{" "}
                </Link>
              </div>
            </div>
            <div className="courses">
              <h3>Free Playlists</h3>
              <div>
                <a href="https://www.youtube.com/playlist?list=PL2L83ZcMO-5O7zNycUkS5WLgr33J3QxMK">
                  Deep Learning for Computer Vision
                </a>
                <a href="https://www.youtube.com/playlist?list=PL2L83ZcMO-5Ow9Xi-dJqoRc_aZ7g-Ae0V">
                  Introduction to python
                </a>
                <a href="https://www.youtube.com/playlist?list=PL2L83ZcMO-5Pj7y4u4y_oGsbgOt97IV8L">
                  Deep Learning for Image Generation
                </a>
                <a href="https://www.youtube.com/playlist?list=PL2L83ZcMO-5PZN8Ixp5y4wi6atPwbreVl">
                  {" "}
                  Deep Learning for Object Detection{" "}
                </a>
              </div>
            </div>

            <div className="courses">
              <h3>Socials</h3>

              <div>
                <a href="https://www.youtube.com/channel/UC4vLiApNM0VEYE-WQCjcscA">
                  YouTube
                </a>
                <a href="https://twitter.com/neulearndotai">Twitter</a>
                <a href="https://www.facebook.com/neuralearndotai">Facebook</a>
                <a href="https://www.linkedin.com/company/neuralearn">
                  LinkedIn
                </a>
              </div>
            </div>
          </div>

          <div className="f-copyright">
            <div className="copyright">
              Copyright {year} Neuralearn.ai {"   "} All rights reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
