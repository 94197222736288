import React from "react";
import dp1 from "../assets/dp/dp1.svg";
import dp2 from "../assets/dp/dp2.svg";
import dp4 from "../assets/dp/dp4.svg";
import dp3 from "../assets/dp/dp3.svg";
import dp5 from "../assets/dp/dp5.svg";
import dp6 from "../assets/dp/dp6.svg";
import dp7 from "../assets/dp/dp7.svg";
import dp8 from "../assets/dp/dp8.svg";
import dp9 from "../assets/dp/dp9.svg";
import dp10 from "../assets/dp/dp10.svg";
import dp11 from "../assets/dp/dp11.svg";
import dp12 from "../assets/dp/dp12.svg";
import dp13 from "../assets/dp/dp13.svg";

import dp15 from "../assets/dp/dp15.svg";
import dp16 from "../assets/dp/dp16.svg";
import dp17 from "../assets/dp/dp17.svg";
import dp18 from "../assets/dp/dp18.svg";
import dp19 from "../assets/dp/dp19.svg";
import dp20 from "../assets/dp/dp20.svg";
import dp21 from "../assets/dp/dp21.svg";
import dp22 from "../assets/dp/dp22.svg";
import dp23 from "../assets/dp/dp23.svg";

const DeepLearning = () => {
  return (
    <div>
      <div>
        <div className="learningPathbody">
          <section className="learning-path-html">
            <h2 className="title my-5">Learning Path</h2>

            <section className="path">
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">Introduction</h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp1} alt="intro" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Welcome</p>
                    </div>
                    <div className="lesson">
                      <p>General Introduction</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Applications of Deep Learning in Computer Vision</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Course Structure</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        Tensors and Variables
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp2} alt="intro" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Basics</p>
                    </div>
                    <div className="lesson">
                      <p>Initialization and Casting</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Indexing</p>
                    </div>
                    <div className="lesson">
                      <p>Maths Operations</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Linear Algebra Operations</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Common TensorFlow Functions</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Ragged Tensors</p>
                    </div>
                    <div className="lesson">
                      <p>Sparse Tensors</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>String Tensors</p>
                    </div>
                    <div className="lesson">
                      <p>Variables</p>
                    </div>
                    <div className="lesson">
                      <p>Loading and Saving Models</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        Building Neural Networks with TensorFlow{" "}
                        <span style={{ color: "orange" }}>
                          [Car Price Prediction]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp3} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Task Understanding</p>
                    </div>
                    <div className="lesson">
                      <p>Data Preparation</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Linear Regression Model</p>
                    </div>

                    <div className="lesson">
                      <p>Error Sanctioning</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Training and Optimization</p>
                    </div>

                    <div className="lesson">
                      <p>Performance Measurement</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Validation and Testing</p>
                    </div>

                    <div className="lesson">
                      <p>Corrective Measures</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        Building Convolutional Neural Networks with TensorFlow{" "}
                        <span
                          style={{
                            color: "orange",
                          }}
                        >
                          [Malaria Diagnosis]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp4} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Task Understanding</p>
                    </div>

                    <div className="lesson">
                      <p>Data Preparation</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Data Visualization</p>
                    </div>

                    <div className="lesson">
                      <p>Data Processing</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>How and Why Convolutional Neural Networks Work</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Building ConvNets with Tensorflow</p>
                    </div>
                    <div className="lesson">
                      <p>Binary Crossentropy Loss</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Training ConvNets</p>
                    </div>
                    <div className="lesson">
                      <p>Model Evaluation and Testing</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Loading and Saving tensorflow models to gdrive</p>
                    </div>
                    <br />
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        Building more Advanced Models with TensorFlow{" "}
                        <span
                          style={{
                            color: "orange",
                          }}
                        >
                          [Malaria Diagnosis]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp5} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Task Understanding</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Data Preparation</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Data Visualization</p>
                    </div>
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Evaluating Classification Models{" "}
                        <span
                          style={{
                            color: "orange",
                          }}
                        >
                          [Malaria Diagnosis]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp6} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Precision, Recall, Accuracy</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Confusion Matrix</p>
                    </div>

                    <div className="lesson">
                      <p>ROC Curve</p>
                    </div>
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Improving Model Performance{" "}
                        <span
                          style={{
                            color: "orange",
                          }}
                        >
                          [Malaria Diagnosis]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp7} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Tensorflow Callbacks</p>
                    </div>

                    <div className="lesson">
                      <p>Learning Rate Scheduling</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Model Checkpointing</p>
                    </div>

                    <div className="lesson">
                      <p>Understanding BLEU score</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Mitigating Overfitting and Underfitting</p>
                    </div>
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Data Augmentation{" "}
                        <span
                          style={{
                            color: "orange",
                          }}
                        >
                          [Malaria Diagnosis]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp8} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Data augmentation with tf.image </p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Data augmentation with Keras Layers</p>
                    </div>
                    <div className="lesson">
                      <p>Mixup Augmentation</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Cutmix Augmentation</p>
                    </div>
                    <div className="lesson">
                      <p>Albumentations</p>
                    </div>
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Advanced Tensorflow{" "}
                        <span
                          style={{
                            color: "orange",
                          }}
                        >
                          [Malaria Diagnosis]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp9} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Custom Loss and Metrics</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Eager and Graph Modes</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Custom Training Loops</p>
                    </div>
                    <br />
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Tensorboard Integration{" "}
                        <span
                          style={{
                            color: "orange",
                          }}
                        >
                          [Malaria Diagnosis]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp10} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Data Logging</p>
                    </div>

                    <div className="lesson">
                      <p>View Model Graphs</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Hyperparameter Tuning</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Profiling and Visualizations</p>
                    </div>
                    <br />
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        MLOps with Weights and Biases{" "}
                        <span
                          style={{
                            color: "orange",
                          }}
                        >
                          [Malaria Diagnosis]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp11} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Experiment Tracking</p>
                    </div>

                    <div className="lesson">
                      <p>Hyperparameter Tuning</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Dataset Versioning</p>
                    </div>
                    <div className="lesson">
                      <p>Model Versioning</p>
                    </div>
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Human Emotions Detection
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp12} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />

                    <div className="lesson">
                      <p>Data Preparation</p>
                    </div>

                    <div className="lesson">
                      <p>Modeling and Training</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Data Augmentation</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Tensorflow records</p>
                    </div>
                    <br />
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Modern Convolutional Neural Networks{" "}
                        <span
                          style={{
                            color: "orange",
                          }}
                        >
                          [Human Emotions Detection]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp13} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>AlexNet</p>
                    </div>

                    <div className="lesson">
                      <p>VGGNet</p>
                    </div>
                    <br />

                    <div className="lesson">
                      <p>ResNet</p>
                    </div>

                    <div className="lesson">
                      <p>Coding ResNets from Scratch</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>MobileNet</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>How APIs work</p>
                    </div>
                    <div className="lesson">
                      <p>Building API with Fastapi</p>
                    </div>

                    <div className="lesson">
                      <p>EfficientNet</p>
                    </div>
                    <br />

                    <br />
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Transfer Learning{" "}
                        <span style={{ color: "orange" }}>
                          [Human Emotions Detection]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp15} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Feature Extraction</p>
                    </div>

                    <br />
                    <br />
                    <div className="lesson">
                      <p>Finetuning</p>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Understanding the Blackbox{" "}
                        <span style={{ color: "orange" }}>
                          [Human Emotions Detection]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp16} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Visualizing Intermediate Layers</p>
                    </div>

                    <br />
                    <br />
                    <div className="lesson">
                      <p>Grad-Cam Method</p>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Class Imbalance and Ensembling{" "}
                        <span style={{ color: "orange" }}>
                          [Human Emotions Detection]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp17} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Ensembling</p>
                    </div>

                    <br />
                    <br />
                    <div className="lesson">
                      <p>Class Imbalance</p>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Transformers in Vision{" "}
                        <span style={{ color: "orange" }}>
                          [Human Emotions Detection]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp18} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Understanding VITs</p>
                    </div>
                    <div className="lesson">
                      <p>Building VITs from Scratch</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Finetuning Huggingface VITs</p>
                    </div>
                    <div className="lesson">
                      <p>Model Evaluation with Wandb</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Data Efficient Transformers</p>
                    </div>
                    <div className="lesson">
                      <p>Swin Transformers</p>
                    </div>
                    <br />
                  </div>
                </div>
              </article>

              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Model Deployment{" "}
                        <span style={{ color: "orange" }}>
                          [Human Emotions Detection]
                        </span>
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp19} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Understanding Quantization</p>
                    </div>
                    <div className="lesson">
                      <p>Conversion from to Onnx Model</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Quantization of Onnx Model</p>
                    </div>
                    <div className="lesson">
                      <p>Quantization Aware Training</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Conversion to TensorflowLite model</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>How APIs work</p>
                    </div>
                    <div className="lesson">
                      <p>Building API with Fastapi</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Deploying API to the Cloud</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Load Testing API</p>
                    </div>
                    <br />
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Object Detection with YOLO
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp20} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Basics of object detection</p>
                    </div>
                    <div className="lesson">
                      <p>Understanding YOLO algorithm</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Dataset Preparation</p>
                    </div>
                    <div className="lesson">
                      <p>Building and Training YOLO from scratch</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Testing</p>
                    </div>

                    <div className="lesson">
                      <p>Performance Measurement with Mean Average Precision</p>
                    </div>

                    <br />
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Image Segmentation with UNet
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp21} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Understanding Image Segmentation</p>
                    </div>
                    <div className="lesson">
                      <p>Training and Optimization</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Class weighting and reduced network</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">People Counting</h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp22} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Understanding People Counting</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Dataset Preparation</p>
                    </div>
                    <div className="lesson">
                      <p>Building CSRNET</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Data Augmentation</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">Image Generation</h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={dp23} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Variational Autoencoders</p>
                    </div>
                    <div className="lesson">
                      <p>Digits generation with VAEs</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>How GANs work</p>
                    </div>
                    <div className="lesson">
                      <p>The GAN loss</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Improving GAN training</p>
                    </div>
                    <div className="lesson">
                      <p>Face Generation with GANs</p>
                    </div>
                  </div>
                </div>
              </article>
            </section>
          </section>

          <div className="mt-5 ms-5" style={{ paddingTop: "1rem" }}>
            <h2 className="title mt-5">Learning Never Ends!!!</h2>
            <h2
              className="mt-3"
              style={{
                color: "#D16500",
                fontSize: "2rem",
              }}
            >
              This Course Will Constantly be Updated
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeepLearning;
