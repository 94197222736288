import React from 'react'

import './Carosel.css'
import image2 from '../../../assets/images/image2.png'
import image3 from '../../../assets/images/image3.png'
import image4 from '../../../assets/images/image4.png'
import image5 from '../../../assets/images/image5.png'

const Carosel = () => {

  return (
    // <motion.div whileInView={{ x: [-100, 0], opacity: [0, 1] }} transition={{ duration: 1.5 }}>
    <div className='builtContainer'>
      <div className='main-built'>
        <div className=' built1'>
          <div className=' buit-text mb-5'>
            Built And Deployed Usable
            <br /> Computer Vision Solutions
          </div>
          <div>
            <img className='image1' src={image5} alt='' />
          </div>
        </div>
        <div className=''>
          <div className=''>
            <div>
              <img className='image2' src={image4} alt='' />
            </div>
            <div className='image3Container'>
              <img className='image3' src={image2} alt='' />
            </div>
          </div>
        </div>
        <div className='image4-container'>
          <img className='image4' src={image3} alt='' />
          <div className='bubbles'>
            <div className='buble1'></div>
            <div className='buble2'></div>
            <div className='buble3'></div>
            <div className='buble4'></div>
          </div>
        </div>
      </div>
    </div>
  // </motion.div>
  )
}

export default Carosel
