import React from "react";
import BannerComponent from "../../components/banner/BannerComponent";
import CourseComponent from "../../components/course/CourseComponent";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";

import "./Course.scss";
import Discount from "../../components/dicount/Discount";

const Course = () => {
  return (
    <div>
      <Discount />
      <Navbar />
      <BannerComponent />
      <CourseComponent title="Computer Vision" />
      <Footer />
    </div>
  );
};

export default Course;
