import React from 'react'
import { Banner } from './Banner'
import './Banner.css'
// images
import review1 from '../../assets/reviews/review_1.png'
import review2 from '../../assets/reviews/review_2.png'
import review3 from '../../assets/reviews/review_3.png'
import review4 from '../../assets/reviews/review_4.png'
import review5 from '../../assets/reviews/review_5.png'
import review6 from '../../assets/reviews/review_6.png'
import review7 from '../../assets/reviews/review_7.png'
import review8 from '../../assets/reviews/review_8.png'
import review9 from '../../assets/reviews/review_9.png'
import review10 from '../../assets/reviews/review_10.png'
import review11 from '../../assets/reviews/review_11.png'
import review12 from '../../assets/reviews/review_12.png'
import review13 from '../../assets/reviews/review_13.png'
import review14 from '../../assets/reviews/review_14.png'
import review15 from '../../assets/reviews/review_15.png'
import review16 from '../../assets/reviews/review_16.png'
import review17 from '../../assets/reviews/review_17.png'
import review18 from '../../assets/reviews/review_18.png'
import review19 from '../../assets/reviews/review_19.png'
import review20 from '../../assets/reviews/review_20.png'
import review21 from '../../assets/reviews/review_21.png'
import review22 from '../../assets/reviews/review_22.png'
import review23 from '../../assets/reviews/review_23.png'
import review24 from '../../assets/reviews/review_24.png'
import review25 from '../../assets/reviews/review_25.png'
import review26 from '../../assets/reviews/review_26.png'
import review27 from '../../assets/reviews/review_27.png'
import review28 from '../../assets/reviews/review_28.png'
import review29 from '../../assets/reviews/review_29.png'
import review30 from '../../assets/reviews/review_30.png'
import review31 from '../../assets/reviews/review_31.png'
import review32 from '../../assets/reviews/review_32.png'
import review33 from '../../assets/reviews/review_33.png'
import review34 from '../../assets/reviews/review_34.png'
import review35 from '../../assets/reviews/review_35.png'
import review36 from '../../assets/reviews/review_36.png'
import review37 from '../../assets/reviews/review_37.png'
import review38 from '../../assets/reviews/review_38.png'
import review39 from '../../assets/reviews/review_39.png'
import review40 from '../../assets/reviews/review_40.png'
import review41 from '../../assets/reviews/review_41.png'
// import review42 from '../../assets/reviews/review_42.png'
import review43 from '../../assets/reviews/review_43.png'
import review44 from '../../assets/reviews/review_44.png'
import review45 from '../../assets/reviews/review_45.png'
import review46 from '../../assets/reviews/review_46.png'
import review47 from '../../assets/reviews/review_47.png'
import review48 from '../../assets/reviews/review_48.png'
import review49 from '../../assets/reviews/review_49.png'
import review50 from '../../assets/reviews/review_50.png'
import review51 from '../../assets/reviews/review_51.png'
import review52 from '../../assets/reviews/review_52.png'
import review53 from '../../assets/reviews/review_53.png'
import review54 from '../../assets/reviews/review_54.png'
import review55 from '../../assets/reviews/review_55.png'
import review56 from '../../assets/reviews/review_56.png'
// import review57 from '../../assets/reviews/review_57.png'
// import review58 from '../../assets/reviews/review_58.png'
// import r1 from '../../assets/reviews/review.png'
const images = [
  review1,
  review2,
  review3,
  review4,
  review5,
  review6,
  review7,
  review8,
  review9,
  review10,
  review11,
  review12,
  review13,
  review14,
  review15,
  review16,
  review17,
  review18,
  review19,
  review20,
  review21,
  review22,
  review23,
  review24,
  review25,
  review26,
  review27,
  review28,
  review29,
  review30,
  review31,
  review32,
  review33,
  review34,
  review35,
  review36,
  review37,
  review38,
  review39,
  review40,
  review41,
  // review42,
  review43,
  review44,
  review45,
  review46,
  review47,
  review48,
  review49,
  review50,
  review51,
  review52,
  review53,
  review54,
  review55,
  review56,
  // review57,
  // review58

].map((image) => ({
 id: Math.floor(Math.random() * 1000000),
image}))
const BannerComponent = () => {
  return (
    <div className='bannerComponent'>
      <Banner images={images} speed={300000} />
    </div>
  )
}

export default BannerComponent
