import React from "react";
import gn1 from "../assets/gan/gn1.svg";
import gn2 from "../assets/gan/gn2.svg";
import gn3 from "../assets/gan/gn3.svg";
import gn4 from "../assets/gan/gn4.svg";
import gn5 from "../assets/gan/gn5.svg";
import gn6 from "../assets/gan/gn6.svg";
import gn7 from "../assets/gan/gn7.svg";
import gn8 from "../assets/gan/gn8.svg";
const GAN = () => {
  return (
    <div>
      <div>
        <div className="learningPathbody">
          <section className="learning-path-html">
            <h2 className="title my-5">Learning Path</h2>

            <section className="path">
              <article>
                <div className="left">
                  <br />
                  <br />
                  <br />
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">Introduction</h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={gn1} alt="intro" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Welcome</p>
                    </div>
                    <div className="lesson">
                      <p>General Introduction</p>
                    </div>
                    <div className="lesson">
                      <p>Course Structure</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        Variational Autoencoders
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={gn2} alt="intro" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Understanding VAEs</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Digits Generation with VAEs</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        DCGANs
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={gn3} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>How GANs work</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>The GAN loss</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Improving GAN training</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Face Generation with GANs</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        Face Generation with GANs
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={gn4} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Understanding WGANs</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Data Visualization</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>How and Why Convolutional Neural Networks Work</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4
                        className="chapter-title"
                        style={{
                          fontSize: "1.4rem",
                        }}
                      >
                        How and Why Convolutional Neural Networks Work
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={gn5} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <br />
                    <div className="lesson">
                      <p>Understanding ProGANs</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>ProGANs in practice</p>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Image super resolution with SRGan
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={gn6} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Understanding SRGANs</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>SRGan in practice</p>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">
                        Face mask removal with CycleGAN
                      </h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={gn7} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Understanding Cyclegans</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Cyclegans in Practice</p>
                    </div>

                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </article>
              <article>
                <div className="left">
                  <div className="top">
                    <div className="chapter-box">
                      <h4 className="chapter-title">Diffusion Models</h4>
                    </div>
                    <div className="line"></div>
                  </div>
                  <div className="image-block">
                    <img src={gn8} alt="graph" />
                  </div>
                </div>
                <div className="right">
                  <div className="wall"></div>
                  <div className="lessons">
                    <div className="lesson">
                      <p>Understanding Diffusion Models</p>
                    </div>

                    <br />
                    <div className="lesson">
                      <p>Building the Unet Model </p>
                    </div>

                    <div className="lesson">
                      <p>Timestep embeddings</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Understanding Attention</p>
                    </div>
                    <br />
                    <div className="lesson">
                      <p>Training</p>
                    </div>
                    <div className="lesson">
                      <p>Sampling</p>
                    </div>
                  </div>
                </div>
              </article>
            </section>
          </section>

          <div className="mt-5 ms-5" style={{ paddingTop: "1rem" }}>
            <h2 className="title mt-5">Learning Never Ends!!!</h2>
            <h2
              className="mt-3"
              style={{
                color: "#D16500",
                fontSize: "2rem",
              }}
            >
              This Course Will Constantly be Updated
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GAN;
